import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Profile } from '../interfaces/profile';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRow,
    GridRowProps,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    RowPropsOverrides,
} from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles()((theme) => ({
    displayNone: {
        display: 'none',
    },
    profileTitle: {
        fontSize: 14,
    },
}));

const permissionTable = {
    r: 'read',
    w: 'write',
    n: 'none',
};

interface TransferListProps {
    leftHeader: any;
    rightHeader: any;
    columns: any;
    leftItems: any;
    rightItems: any;
    onLeftChanged: (arg: any) => void;
    onRightChanged: (arg: any) => void;
    editable: boolean;
    onChangeProfile?: (profile: Profile) => void;
    profiles?: Profile[];
}

function TransferList({
    leftHeader,
    rightHeader,
    columns,
    leftItems,
    rightItems,
    onLeftChanged,
    onRightChanged,
    editable,
    onChangeProfile,
    profiles,
}: TransferListProps) {
    const [leftColumns, setLeftColumns] = React.useState<GridColDef[]>(columns);
    const [rightColumns, setRightColumns] = React.useState<GridColDef[]>(columns);
    const [left, setLeft] = React.useState(leftItems);
    const [right, setRight] = React.useState(rightItems);
    const rightRef = React.useRef(right);
    const leftRef = React.useRef(left);

    const getPermission = (profileName: string) => {
        if (profiles) {
            const profile = profiles.find((x) => x.name === profileName);
            if (profile) {
                return {
                    // @ts-ignore
                    source: permissionTable[profile.permissions.charAt(0)],
                    // @ts-ignore
                    raw: permissionTable[profile.permissions.charAt(1)],
                    // @ts-ignore
                    transient: permissionTable[profile.permissions.charAt(2)],
                    // @ts-ignore
                    curated: permissionTable[profile.permissions.charAt(3)],
                };
            }
        }
        return null;
    };
    const displayPermissions = (profile: string) => {
        const p = getPermission(profile);

        return (
            <ul>
                <li>Source: {p ? p.source : ''}</li>
                <li>Raw: {p ? p.raw : ''}</li>
                <li>Transient: {p ? p.transient : ''}</li>
                <li>Curated: {p ? p.curated : ''}</li>
            </ul>
        );
    };

    const _setRight = (data: any) => {
        rightRef.current = data;
        setRight(data);
    };
    const _setLeft = (data: any) => {
        leftRef.current = data;
        setLeft(data);
    };

    useEffect(() => {
        _setLeft(leftItems);
        _setRight(rightItems);
    }, [leftItems, rightItems]);

    useEffect(() => {
        setLeftColumns([
            ...columns,
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 0.15,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => (
                    <IconButton onClick={(event) => handleToRight(event, params.row)}>
                        <AddIcon />
                    </IconButton>
                ),
            },
        ]);

        let rightColumns = [];
        if (editable) {
            rightColumns.push({
                field: '',
                headerName: '',
                flex: 0.05,
                sortable: false,
                // TODO: display expandIcon when the details are expanded
                renderCell: () => (
                    <IconButton>
                        <ChevronRightIcon />
                    </IconButton>
                ),
            });
        }

        rightColumns = [
            ...rightColumns,
            ...columns,
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 0.2,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => (
                    <IconButton onClick={(event) => handleToLeft(event, params.row)}>
                        <RemoveIcon />
                    </IconButton>
                ),
            },
        ];

        setRightColumns(rightColumns);
    }, [columns, editable]);

    const handleToRight = (event: any, item: any) => {
        event.preventDefault();
        const l = left.filter((m: any) => m.username !== item?.username);
        const r = [...right, item];
        _setRight(r);
        _setLeft(l);
        onLeftChanged({ item, left, right });
    };

    const handleToLeft = (event: any, item: any) => {
        event.preventDefault();
        const r = right.filter((m: any) => m.username !== item?.username);
        const l = [...left, item];
        _setRight(r);
        _setLeft(l);
        onRightChanged({ item, left, right });
    };

    const handleChangeProfile = async (event: any, item: any) => {
        const newRight = rightRef.current.map((x: any) => {
            if (x.username === item.username) {
                const updatedItem = {
                    ...x,
                    profile: event.target.value,
                };
                return updatedItem;
            }
            return x;
        });
        _setRight(newRight);

        if (onChangeProfile) {
            await onChangeProfile({ username: item.username, profile: event.target.value });
        }
    };

    const { classes } = useStyles();

    const CustomToolbar = ({ title }: { title: string }) => {
        return (
            <GridToolbarContainer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        m: 2,
                        mb: 0.5,
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    const CustomRowRightTable = ({ data }: { data: GridRowProps & RowPropsOverrides }) => {
        const rowData = data.row;
        const [showDetails, setShowDetails] = React.useState(false);

        const handleRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!editable) return;
            event.preventDefault();
            setShowDetails(!showDetails);
        };

        return (
            <>
                <GridRow {...data} onClick={handleRowClick} />
                {editable && (
                    <Card hidden={!showDetails}>
                        <CardContent>
                            <Typography
                                className={classes.profileTitle}
                                color="textSecondary"
                                gutterBottom
                            >
                                To change the profile for the current user select one of the
                                profiles in the dropdown. The permission for a profile defines the
                                access level for each dataset zones.
                            </Typography>
                            <div>
                                {rowData?.profile && (
                                    <Select
                                        variant="standard"
                                        disabled={!editable}
                                        value={rowData.profile}
                                        onChange={(event) =>
                                            handleChangeProfile(event, {
                                                profile: rowData.profile,
                                                username: rowData.username,
                                            })
                                        }
                                    >
                                        {profiles?.map((x) => (
                                            <MenuItem key={x.name} value={x.name}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                        ;
                                    </Select>
                                )}
                            </div>
                            {displayPermissions(rowData?.profile)}
                        </CardContent>
                    </Card>
                )}
            </>
        );
    };

    return (
        <Grid container component="span" spacing={2}>
            <Grid container component="span" spacing={5}>
                <Grid component="span" item xs={6}>
                    <DataGrid
                        rows={left}
                        columns={leftColumns}
                        getRowId={(row) => row.username}
                        slots={{
                            toolbar: () => <CustomToolbar title={leftHeader} />,
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 5, page: 0 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        disableColumnMenu
                        disableColumnResize
                        disableRowSelectionOnClick
                    />
                </Grid>
                <Grid component="span" item xs={6}>
                    <DataGrid
                        rows={right}
                        columns={rightColumns}
                        getRowId={(row) => row.username}
                        slots={{
                            toolbar: () => <CustomToolbar title={rightHeader} />,
                            row: (data) => <CustomRowRightTable data={data} />,
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 5, page: 0 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        disableColumnMenu
                        disableColumnResize
                        disableRowSelectionOnClick
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
export default TransferList;
