import React from 'react';
import MBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import { useGlobalContext } from './GlobalContext';

const useStyles = makeStyles()((theme) => ({
    backdrop: {
        zIndex: 10000,
        color: '#fff',
    },
}));

export default function Backdrop() {
    const { classes } = useStyles();
    const globalContext = useGlobalContext();

    return (
        <div>
            <MBackdrop className={classes.backdrop} open={globalContext.backdrop}>
                <CircularProgress color="inherit" />
            </MBackdrop>
        </div>
    );
}
