import React from 'react';
import { useGlobalContext } from '../GlobalContext';
import Switch from '@mui/material/Switch';
import {
    DataGrid,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

export function FeatureSwitches() {
    const { config, toggleFeature } = useGlobalContext();
    const [columns] = React.useState([
        {
            headerName: 'Feature',
            field: 'name',
            flex: 0.9,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            flex: 0.1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Switch
                    checked={params.row.enabled}
                    onChange={() => handleToggleFeature(params.row)}
                    name="toggleFeature"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    color="primary"
                />
            ),
        },
    ]);

    async function handleToggleFeature(rowData: { name: string }) {
        toggleFeature(rowData.name);
    }

    const CustomToolbar = ({ title }: { title: string }) => {
        return (
            <GridToolbarContainer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        m: 2,
                        mb: 0.5,
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    return (
        <React.Fragment>
            <DataGrid
                rows={config.featureSwitches}
                columns={columns}
                getRowId={(row) => row.name}
                slots={{
                    toolbar: () => <CustomToolbar title="Feature switches" />,
                }}
                disableColumnMenu
                disableColumnResize
                disableRowSelectionOnClick
            />
        </React.Fragment>
    );
}
