import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
}));

export default function CircularProgressWrapper() {
    const { classes } = useStyles();

    return (
        <div className={classes.wrapper}>
            <CircularProgress />
        </div>
    );
}
