import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

interface MenuButtonProps {
    file: string;
    onDownload: (file: string) => void;
    onDelete: (file: string) => void;
}

export default function MenuButton({ file, onDownload, onDelete }: MenuButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = () => {
        setAnchorEl(null);
        onDownload(file);
    };

    function deleteItem(message: string, e: any) {
        // @ts-ignore
        alert('Deleting Item', message, e);
        onDelete(file);
    }

    function onCancel(message: string, e: any) {
        // @ts-ignore
        alert('Action cancelled... !!', message, e);
    }

    const handleDelete = (event: any) => {
        window.confirm('Are you sure you wish to delete this item?')
            ? deleteItem('confirm', event)
            : onCancel('cancel', event);
    };

    return (
        <div>
            <Button aria-controls={`action-menu`} aria-haspopup="true" onClick={handleClick}>
                <MoreHorizIcon />
            </Button>
            <Menu
                id={`action-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDownload}>Download</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </div>
    );
}
