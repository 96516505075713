import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useAuth } from '../App/AuthContext';
import { useCommandApi, useQueryApi } from '../Apis';
import { useGlobalContext } from '../App/GlobalContext';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import NewDatasetDialog from '../Datasets/NewDatasetDialog';
import Switch from '@mui/material/Switch';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Dataset } from '../interfaces/dataset';
import debounce from 'lodash/debounce';
import reducer from './reducer';
import { initialState, SortDirection } from './initialState';
import Title from './Title';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170, style: 'link' },
    { id: 'active', label: 'Active', minWidth: 50, style: 'switch' },
    { id: 'type', label: 'Type', minWidth: 100 },
    { id: 'region', label: 'Region', minWidth: 100 },
    { id: 'role', label: 'Profile', minWidth: 100 },
    { id: 'steward', label: 'Steward', minWidth: 50 },
    { id: 'member', label: 'Member', minWidth: 50 },
];

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    activePopover: {
        padding: theme.spacing(2),
    },
    smallIcon: {
        position: 'relative',
        top: theme.spacing(0.5),
    },
    input: {
        width: '400px',
    },
    spinner: {
        marginLeft: '50%',
    },
}));

export default function DataSets() {
    const { identity, authSession, logout } = useAuth();
    const { config, setBackdrop, addError, addNotification } = useGlobalContext();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);
    const { classes } = useStyles();
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const NotMemberInfoText = 'You must be a member of the dataset to set it to active';
    const MaxActiveDatasetsInfoText =
        'You reached the maximum number of active datasets. Deactivate one in order to activate this one.';
    const [loading, setLoading] = useState(false);

    async function initializeData() {
        setLoading(true);
        try {
            const user = await queryApi.getUser(identity!.username);

            dispatch({ type: 'setUser', payload: user });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    async function handleSubmitNewDatasetDialog(dataset: Dataset) {
        try {
            await commandApi.createDataset(dataset);
            dispatch({ type: 'setOpenEditDialog', payload: false });
            initializeData();
            setBackdrop(false);
        } catch (err) {
            logout();
        }
    }

    function descendingComparator(a: any, b: any, orderBy: string) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order: SortDirection, orderBy: string) {
        return order === SortDirection.DESC
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array: any[], comparator: any) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    async function handleActiveChange(event: any) {
        const datasetMember = isMemberOfDataset(event.target.name);
        const toggleActiveDataset = () =>
            commandApi
                .toggleActiveDataset(event.target.name, identity!.username)
                .then(() => initializeData())
                .catch(addError)
                .finally(() => setBackdrop(false));

        if (!datasetMember) {
            dispatch({
                type: 'setAnchor',
                payload: { element: event.currentTarget, text: NotMemberInfoText },
            });

            return;
        }

        if (datasetMember.active !== 1) {
            if (!state.user?.show_policy_size_limit_notification) {
                setBackdrop(true);
                toggleActiveDataset();
            } else {
                dispatch({
                    type: 'setAnchor',
                    payload: { element: event.currentTarget, text: MaxActiveDatasetsInfoText },
                });
            }
        } else {
            setBackdrop(true);
            toggleActiveDataset();
        }
    }

    function isMemberOfDataset(ds_name: string) {
        return state.user!.member_of!.find((x) => x.name === ds_name);
    }

    const handleInputChange = useCallback((event: any) => {
        const value = event.target.value;

        dispatch({ type: 'filter', payload: value });
    }, []);
    const debouncedHandleInputChange = debounce(handleInputChange, 200);

    function EnhancedTableHead(props: any) {
        const { classes, order, orderBy, onRequestSort } = props;
        const orderLabel = order === SortDirection.DESC ? 'desc' : 'asc';
        const createSortHandler = (property: string) => (event: any) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            sortDirection={orderBy === column.id ? orderLabel : false}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? orderLabel : 'asc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function renderItemStyle(style: string, value: string, key: string) {
        if (style === 'link') {
            return <Link to={'/datasets/' + value}>{value}</Link>;
        } else if (style === 'switch') {
            return (
                <Tooltip title="Set dataset active or inactive">
                    <Switch
                        name={key}
                        checked={!!value}
                        color="primary"
                        onChange={handleActiveChange}
                    ></Switch>
                </Tooltip>
            );
        }
        return value;
    }

    useEffect(() => {
        initializeData();
    }, [identity, queryApi]);

    useEffect(() => {
        if (state.user && state.user.show_policy_size_limit_notification) {
            addNotification(
                'datasets-limit-almost-reached',
                'Active dataset limit almost reached',
                'You have almost reached the maximum dataset permission limit. Please deactivate some datasets in order to access new or other dataset.',
                '',
            );
        }
    }, [state.user]);

    return (
        <Card>
            <NewDatasetDialog
                openState={state.openEditDialog}
                onSubmitForm={handleSubmitNewDatasetDialog}
                onCancel={() => dispatch({ type: 'setOpenEditDialog', payload: false })}
            />
            <Popover
                id={'active-popover'}
                open={Boolean(state.anchorEl)}
                anchorEl={state.anchorEl}
                onClose={() =>
                    dispatch({
                        type: 'setAnchor',
                        payload: { element: null, text: state.anchorText },
                    })
                }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.activePopover}>
                    <InfoIcon className={classes.smallIcon}></InfoIcon>
                    {state.anchorText}
                </Typography>
            </Popover>
            <CardHeader
                title={<Title className={classes.input} onChange={debouncedHandleInputChange} />}
                action={
                    <Tooltip title="Create new dataset" aria-label="add">
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => dispatch({ type: 'setOpenEditDialog', payload: true })}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                }
            />
            <CardContent>
                {loading ? (
                    <CircularProgress className={classes.spinner} />
                ) : (
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    classes={classes}
                                    order={state.order}
                                    orderBy={state.orderBy}
                                    onRequestSort={(evt: unknown, property: string) =>
                                        dispatch({ type: 'sort', payload: property })
                                    }
                                    rowCount={state.rows.length}
                                />
                                <TableBody>
                                    {stableSort(
                                        state.rows,
                                        getComparator(state.order, state.orderBy),
                                    )
                                        .slice(
                                            state.page * state.rowsPerPage,
                                            state.page * state.rowsPerPage + state.rowsPerPage,
                                        )
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.name}
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id}>
                                                                {renderItemStyle(
                                                                    column.style!,
                                                                    value,
                                                                    row.name,
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={state.rows.length}
                            rowsPerPage={state.rowsPerPage}
                            page={state.page}
                            onPageChange={(evt, page) =>
                                dispatch({ type: 'setPage', payload: page })
                            }
                            onRowsPerPageChange={(evt) =>
                                dispatch({
                                    type: 'changeRowsPerPage',
                                    payload: parseInt(evt.target.value),
                                })
                            }
                        />
                    </Paper>
                )}
            </CardContent>
        </Card>
    );
}
