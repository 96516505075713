import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

interface CreateFolderDialogProps {
    openState: boolean;
    onCancel: (value: string) => void;
    onSave: (folderName: string) => void;
    prefix: string;
}

function CreateFolderDialog({ openState, onCancel, onSave, prefix }: CreateFolderDialogProps) {
    const [folderName, setFolderName] = React.useState('');
    const [folderNameError, setFolderNameError] = React.useState(false);
    const [folderNameErrorText, setFolderNameErrorText] = React.useState('');

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleEnter = () => {
        setFolderName('');
        setFolderNameError(true);
        setFolderNameErrorText('Folder name cannot be empty');
    };

    const validateFolderName = (folderName: string) => {
        const folderNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{1,61}[a-zA-Z0-9]$/;
        return folderName && folderNameRegex.test(folderName);
    };

    const handleFolderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFolderName = event.target.value;
        const isValidFolderName = validateFolderName(newFolderName);

        if (isValidFolderName) {
            setFolderNameError(false);
            setFolderNameErrorText('');
        } else {
            setFolderNameError(true);
            setFolderNameErrorText(
                'Invalid folder name. The length must be between 3 and 63 characters.\nMust start and end with an alphanumeric character, and can contain letters, numbers, periods, hyphens, and underscores. ',
            );
        }

        setFolderName(newFolderName);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onSave(folderName);
    };

    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableEscapeKeyDown={true}
                TransitionProps={{
                    onEnter: handleEnter,
                }}
            >
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle id="create-folder-dialog-title">
                        {'Create folder in ' + prefix}
                    </DialogTitle>

                    <DialogContent>
                        <TextField
                            variant="standard"
                            autoFocus={true}
                            margin="dense"
                            id="folder-name-textfield"
                            label="Folder name"
                            fullWidth={true}
                            value={folderName}
                            error={folderNameError}
                            helperText={folderNameErrorText}
                            onChange={handleFolderNameChange}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary" disabled={folderNameError}>
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default CreateFolderDialog;
