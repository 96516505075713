import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../App/AuthContext';
import NewDatasetDialog from './NewDatasetDialog';
import { useCommandApi, useQueryApi } from '../Apis';
import { useGlobalContext } from '../App/GlobalContext';
import { Dataset } from '../interfaces/dataset';
import {
    DataGrid,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Box, Tooltip, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

function Datasets() {
    const { authSession, logout } = useAuth();
    const { config, setBackdrop } = useGlobalContext();
    const navigate = useNavigate();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);

    const [selectedDataset, setSelectedDataset] = React.useState(null as Dataset | null);
    const [openEditDialogState, setOpenEditDialog] = React.useState(false);
    const [user, setUser] = React.useState(false);

    const { identity } = useAuth();

    const [state, setState] = React.useState({
        columns: [
            { headerName: 'Dataset', field: 'name', flex: 0.3 },
            { headerName: 'Region', field: 'region', flex: 0.2 },
            { headerName: 'Domain', field: 'domain', hide: true },
            { headerName: 'Owner', field: 'owner', hide: true },
            { headerName: 'Cost Center', field: 'cost_center', flex: 0.2 },
            { headerName: 'Company', field: 'company', hide: true },
            {
                headerName: 'Managed',
                field: 'managed',
                flex: 0.2,
                renderCell: (params: GridRenderCellParams) => {
                    const rowData: Dataset = params.row;
                    if (rowData.managed === true) {
                        return <CheckIcon />;
                    } else {
                        return <RemoveIcon />;
                    }
                },
            },
            {
                headerName: 'Actions',
                field: 'actions',
                flex: 0.1,
                renderCell: (params: GridRenderCellParams) => {
                    const dataset: Dataset = params.row;
                    return (
                        <Tooltip title="Edit Dataset">
                            <IconButton
                                onClick={() => {
                                    setSelectedDataset(dataset);
                                    handleGotoEditDataset(dataset);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    );
                },
            },
        ],
        data: [],
    });

    async function initializaData() {
        try {
            const user = await queryApi.getUser(identity!.username);
            setUser(user);
        } catch (err) {
            logout();
        }
    }

    useEffect(() => {
        initializaData();
    }, [identity, authSession, queryApi, logout]);

    useEffect(() => {
        setState((state) => ({
            columns: state.columns,
            // @ts-ignore
            data: user['steward_on'] ? user['steward_on'] : [],
        }));
    }, [user]);

    const handleOpenNewDialog = () => {
        setOpenEditDialog(true);
    };

    const handleCancelNewDatasetDialog = () => {
        setOpenEditDialog(false);
    };

    async function handleSubmitNewDatasetDialog(dataset: Dataset) {
        try {
            await commandApi.createDataset(dataset);
            setOpenEditDialog(false);
            initializaData();
            setBackdrop(false);
        } catch (err) {
            logout();
        }
    }

    const handleGotoEditDataset = (dataset: Dataset) => {
        navigate('/datasets/' + dataset.name);
    };

    const CustomToolbar = ({ title }: { title: string }) => {
        return (
            <GridToolbarContainer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        m: 2,
                        mb: 0.5,
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <GridToolbarQuickFilter />
                        <Tooltip title="New Dataset">
                            <IconButton
                                disabled={
                                    identity!.steward === 'NA' || identity!.steward === undefined
                                }
                                onClick={handleOpenNewDialog}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </GridToolbarContainer>
        );
    };

    return (
        <React.Fragment>
            <NewDatasetDialog
                openState={openEditDialogState}
                onSubmitForm={handleSubmitNewDatasetDialog}
                onCancel={handleCancelNewDatasetDialog}
            />
            <DataGrid
                rows={state.data}
                columns={state.columns}
                getRowId={(row: any) => row.name}
                slots={{
                    toolbar: () => <CustomToolbar title="My Datasets" />,
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5, page: 0 } },
                    columns: {
                        columnVisibilityModel: {
                            domain: false,
                            owner: false,
                            company: false,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 25]}
                disableColumnMenu
                disableColumnResize
                disableRowSelectionOnClick
            />
        </React.Fragment>
    );
}

export default Datasets;
