import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Dataset } from '../../interfaces/dataset';

interface DeleteDialogProps {
    dataset: Dataset;
    openState: boolean;
    onOk: () => void;
    onCancel: () => void;
}

export default function DeleteDialog({ dataset, openState, onOk, onCancel }: DeleteDialogProps) {
    const [okButtonDisabled, setOkButtonDisabled] = React.useState(true);
    const [datasetDeletionConfirm, setDatasetDeletionConfirm] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorText, setNameErrorText] = React.useState('');

    const validate = (event: any) => {
        if (event.target.value !== dataset.name) {
            setNameError(true);
            setNameErrorText('Dataset name does not match');
            setOkButtonDisabled(true);
        } else {
            setNameError(false);
            setNameErrorText('');
            setOkButtonDisabled(false);
        }
        setDatasetDeletionConfirm(event.target.value);
    };

    return (
        <Dialog open={openState} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Are you sure, you want to delete {dataset ? dataset.name : ''}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="subtitle1">All data will be deleted!</Typography>
                    <Typography variant="subtitle2">
                        Please type the dataset name to confirm
                    </Typography>

                    <TextField
                        variant="standard"
                        autoFocus
                        margin="dense"
                        id="datasetname"
                        label="Dataset name"
                        fullWidth
                        onChange={validate}
                        error={nameError}
                        helperText={nameErrorText}
                        value={datasetDeletionConfirm ? datasetDeletionConfirm : ''}
                        required={true}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={okButtonDisabled} onClick={onOk} color="primary">
                    Ok
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
