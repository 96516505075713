import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    inputLabel: {
        left: -15,
        top: 10,
    },
}));

interface ProfileDialogProps {
    id: string;
    isOpen: boolean;
    handleClosed: (profile: any) => void;
    handleCancel: () => void;
    message: string;
    title: string;
    profiles: any[];
}

export default function ProfileDialog({
    id,
    isOpen,
    handleClosed,
    handleCancel,
    message,
    title,
    profiles,
}: ProfileDialogProps) {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(isOpen);
    const [profile, setProfile] = React.useState('reader');

    const onClose = () => {
        handleClosed(profile);
    };

    const onCancel = () => {
        handleCancel();
    };

    const handleChangeProfile = (event: any) => {
        setProfile(event.target.value);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <div>
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={onClose}
                aria-labelledby={id}
                aria-describedby={id + '-description'}
            >
                <DialogTitle id={id}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id={id + '-description'}>{message}</DialogContentText>
                </DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id={id + '-select-label'} className={classes.inputLabel}>
                        Profile
                    </InputLabel>
                    <Select
                        variant="standard"
                        value={profile}
                        labelId={id + '-select-label'}
                        onChange={(event) => handleChangeProfile(event)}
                    >
                        {profiles.map((x) => (
                            <MenuItem key={x.name} value={x.name}>
                                {x.name}
                            </MenuItem>
                        ))}
                        ;
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button onClick={onClose} color="primary" autoFocus>
                        Ok
                    </Button>
                    <Button onClick={onCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
