import React, { useEffect } from 'react';
import {
    Paper,
    Typography,
    Tooltip,
    SnackbarCloseReason,
    CircularProgress,
    Box,
} from '@mui/material';
import Title from '../Common/Title';
import { makeStyles } from 'tss-react/mui';
import { useGlobalContext } from '../App/GlobalContext';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useAuth } from '../App/AuthContext';
import { useQueryApi } from '../Apis';
import { getCredentials, getRedshiftStrings } from './actions';

const useStyles = makeStyles()((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    hover: {
        '&:hover': {
            background: 'lightgray',
        },
    },
    spinner: {
        marginLeft: '50%',
        marginTop: '3rem',
        marginBottom: '3rem',
    },
    error: {
        margin: '1rem',
    },
}));

export default function Credentials() {
    const { config } = useGlobalContext();
    const { identity, authSession, logout } = useAuth();
    const queryApi = useQueryApi(config, authSession);
    const { classes } = useStyles();
    const [credentials, setCredentials] = React.useState({
        accessKeyId: '',
        secretAccessKey: '',
        sessionToken: '',
    });
    const [redshiftStrings, setRedshiftStrings] = React.useState({});
    const [openCopyDone, setOpenCopyDone] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<any>(null);

    const callApis = async () => {
        setError(null);
        setLoading(true);
        if (!identity) {
            setError({ message: 'Missing Identity. Try to reload the page.' });
            setLoading(false);
            return;
        }

        try {
            const profiles = await queryApi.getProfiles();
            const user = await queryApi.getUser(identity.username);
            const creds = await getCredentials(identity, config);
            const rsData = await queryApi.getRedshiftLocations();
            const rStrings = getRedshiftStrings(
                user['member_of'],
                identity,
                rsData,
                creds,
                profiles,
            );

            setCredentials(creds);
            setRedshiftStrings(rStrings);
        } catch (error: any) {
            console.error(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyText = (event: any) => {
        const el = document.createElement('textarea');
        el.value = event.target.innerText;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setOpenCopyDone(true);
    };

    const handleCloseCopyDone = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCopyDone(false);
    };

    useEffect(() => {
        callApis();
    }, [queryApi, identity, config, logout]);

    return (
        <React.Fragment>
            {loading && <CircularProgress className={classes.spinner} />}
            {error?.message && (
                <Typography
                    component="h2"
                    variant="h6"
                    color="error"
                    align="center"
                    className={classes.error}
                >
                    ERROR: {error?.message}
                </Typography>
            )}
            <Title>AWS Environment variable (Unix)</Title>
            <Paper>
                <Box sx={{ p: 2 }}>
                    <Tooltip title="Click to copy text">
                        <Typography noWrap onClick={handleCopyText} className={classes.hover}>
                            export AWS_ACCESS_KEY_ID={credentials.accessKeyId}
                            <br />
                            export AWS_SECRET_ACCESS_KEY={credentials.secretAccessKey}
                            <br />
                            export AWS_SESSION_TOKEN={credentials.sessionToken}
                        </Typography>
                    </Tooltip>
                </Box>
            </Paper>
            <br />

            <Title>AWS Environment variable (Windows CMD)</Title>
            <Paper>
                <Box sx={{ p: 2 }}>
                    <Tooltip title="Click to copy text">
                        <Typography noWrap onClick={handleCopyText} className={classes.hover}>
                            set AWS_ACCESS_KEY_ID={credentials.accessKeyId}
                            <br />
                            set AWS_SECRET_ACCESS_KEY={credentials.secretAccessKey}
                            <br />
                            set AWS_SESSION_TOKEN={credentials.sessionToken}
                        </Typography>
                    </Tooltip>
                </Box>
            </Paper>
            <br />
            <Title>AWS Environment variable (Windows PowerShell)</Title>
            <Paper>
                <Box sx={{ p: 2 }}>
                    <Tooltip title="Click to copy text">
                        <Typography noWrap onClick={handleCopyText} className={classes.hover}>
                            $env:AWS_ACCESS_KEY_ID = "{credentials.accessKeyId}"
                            <br />
                            $env:AWS_SECRET_ACCESS_KEY = "{credentials.secretAccessKey}"
                            <br />
                            $env:AWS_SESSION_TOKEN = "{credentials.sessionToken}"
                        </Typography>
                    </Tooltip>
                </Box>
            </Paper>
            <br />
            <Title>AWS Profile</Title>

            <Paper>
                <Box sx={{ p: 2 }}>
                    <Tooltip title="Click to copy text">
                        <Typography noWrap onClick={handleCopyText} className={classes.hover}>
                            [dl_user]
                            <br />
                            aws_access_key_id = {credentials.accessKeyId}
                            <br />
                            aws_secret_access_key = {credentials.secretAccessKey}
                            <br />
                            aws_session_token = {credentials.sessionToken}
                        </Typography>
                    </Tooltip>
                </Box>
            </Paper>
            <br />

            {Object.keys(redshiftStrings).map(function (key) {
                return (
                    <React.Fragment key={key}>
                        <Title>Redshift connect string ({key})</Title>
                        <Paper>
                            <Box sx={{ p: 2 }}>
                                <Tooltip title="Click to copy text">
                                    <Typography
                                        noWrap
                                        onClick={handleCopyText}
                                        className={classes.hover}
                                    >
                                        {
                                            redshiftStrings[key as keyof typeof redshiftStrings][
                                                'jdbcurl'
                                            ]
                                        }
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Paper>
                        <br />

                        <Title>RStudio connect string ({key})</Title>
                        <Paper>
                            <Box sx={{ p: 2 }}>
                                <Tooltip title="Click to copy text">
                                    <Typography
                                        noWrap
                                        onClick={handleCopyText}
                                        className={classes.hover}
                                    >
                                        {
                                            redshiftStrings[key as keyof typeof redshiftStrings][
                                                'rjdbcurl'
                                            ]
                                        }
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Paper>
                    </React.Fragment>
                );
            })}
            <Snackbar
                open={openCopyDone}
                autoHideDuration={1500}
                onClose={handleCloseCopyDone}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={(event) => handleCloseCopyDone(event, 'clickaway')}
                    severity="success"
                >
                    Copied to Clipboard
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
