import React from 'react';
import { Config, GlobalProvider } from './GlobalContext';
import { AuthProvider } from './AuthContext';
import Layout from './Layout';
import ErrorBoundary from './ErrorBoundary';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

interface AppProps {
    config: Config;
}

const App: React.FC<AppProps> = ({ config }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalProvider config={config}>
                <ErrorBoundary>
                    <AuthProvider>
                        <Layout />
                    </AuthProvider>
                </ErrorBoundary>
            </GlobalProvider>
        </ThemeProvider>
    );
};

export default App;
