import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Avatar, Button, Paper, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from '../AuthContext';

const useStyles = makeStyles()((theme) => ({
    main: {
        width: 400,
        display: 'block', // Fix IE 11 issue.
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
}));

function Login() {
    const { classes } = useStyles();
    const { authState, signInText, login } = useAuth();
    return (
        <main className={classes.main}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {signInText}
                </Typography>
                <Button
                    disabled={authState !== null}
                    hidden={authState !== null}
                    onClick={login}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign in
                </Button>
            </Paper>
        </main>
    );
}

// @ts-ignore
export default Login;
