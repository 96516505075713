import React from 'react';
import { Grid, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

type Props = {
    className: string;
    onChange: (event: unknown) => void;
};

export default function Title({ className, onChange }: Props) {
    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item>Datasets</Grid>
            <Grid item>
                <TextField
                    placeholder="Filter datasets by name or description"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                    }}
                    className={className}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    );
}
