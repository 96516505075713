import React, { useEffect, useState, useCallback } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import { useCommandApi, useQueryApi } from '../../../Apis';
import { Identity, useAuth } from '../../../App/AuthContext';
import { useGlobalContext } from '../../../App/GlobalContext';
import Breadcrumbs from './Breadcrumbs';
import MenuButton from './MenuButton';
import {
    formatBytes,
    formatDate,
    mapFilesToTable,
    debounce,
    removeFirstSubstring,
} from './helpers';
import UploadFileDialog from './UploadFileDialog';
import { getDatalakeRoleCredentials } from '../../../Apis/datalakeRoleCredentials';
import { downloadFile } from './downloadFile';
import CreateFolderDialog from './CreateFolderDialog';
import CreateSubsetDialog from './CreateSubsetDialog';
import { Dataset, Subset } from '../../../interfaces/dataset';
import { Files } from '../../../interfaces/file';
import { Column } from '../../../interfaces/column';
import CircularProgressWrapper from '../../../Common/CircularProgressWrapper';

function Alert(props: AlertProps) {
    console.log('popping alert...');
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles()((theme) => ({
    breadcrumb: {
        marginBottom: 33,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    addButton: {
        margin: theme.spacing(1),
    },
    tableContainer: {
        marginTop: 43,
        maxHeight: 440,
    },
    filesEmptyText: {
        color: 'grey',
        fontSize: 18,
        textAlign: 'center',
        marginTop: 24,
    },
    snackbar: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    {
        id: 'size',
        label: 'Size',
        minWidth: 170,
        align: 'right',
        format: (value: any) => formatBytes(value),
    },
    {
        id: 'lastmodified',
        label: 'Last modified',
        minWidth: 170,
        align: 'right',
        format: (value: any) => formatDate(value),
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 100,
        align: 'right',
    },
];

function FileTable(files: Files) {
    const { classes } = useStyles();
    const rows = mapFilesToTable(files);

    return (
        <div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        borderBottom: '1px solid black',
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                    {columns.map((column) => {
                                        const value = row[column.id as keyof typeof TableRow];
                                        if (column.id === 'actions')
                                            return (
                                                <TableCell key="actions" align="right">
                                                    <MenuButton
                                                        file={files.prefixPath + '/' + row.name}
                                                        onDownload={files.onDownloadFile}
                                                        onDelete={files.onDeleteFile}
                                                    />
                                                </TableCell>
                                            );
                                        else
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows.length === 0 && <div className={classes.filesEmptyText}>No files</div>}
        </div>
    );
}

interface FileViewProps {
    dataset: Dataset;
    selectedPath: string;
    onSelect: (path: string) => void;
    onChangeTab: (tabIdx: number) => void;
    onDeleteFolder: (path: string | null) => void;
}

export default function FileView({
    dataset,
    selectedPath,
    onSelect,
    onChangeTab,
    onDeleteFolder,
}: FileViewProps) {
    const { classes } = useStyles();
    const { identity, authSession, logout } = useAuth();
    const { config, addError, setBackdrop } = useGlobalContext();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);
    const [files, setFiles] = useState([]);
    const [searchTerm, setSearchTerm]: [string | null, any] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
    const [showCreateSubsetDialog, setShowCreateSubsetDialog] = useState(false);
    const [actionDone, setOpenActionDone] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [actionSeverity, setActionSeverity]: [any | undefined, any] = useState('');
    const maxKeys = '1000';

    async function callApis() {
        setLoading(true);
        try {
            let d;
            const prefix = selectedPath.split('/').slice(1).join('/');
            const prefixTerm = searchTerm ? (prefix ? '/' + searchTerm : '' + searchTerm) : '';
            if (searchTerm) {
                d = await queryApi.searchDataFiles(
                    dataset['name'],
                    maxKeys,
                    encodeURI(prefix + prefixTerm),
                );
            } else {
                d = await queryApi.getDataFiles(
                    dataset['name'],
                    maxKeys,
                    encodeURI(prefix + prefixTerm),
                );
            }

            setFiles(d['Contents']);
            setLoading(false);
        } catch (err) {
            //logout();
            console.log(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (selectedPath) {
            callApis();
        }
    }, [selectedPath, searchTerm]);

    const onBreadcrumbClick = (path: string) => {
        onSelect(path);
    };

    const handleOnSearch = useCallback(
        debounce((term: string) => {
            setSearchTerm(term);
        }),
        [],
    );

    const handleUploadFile = (event: any) => {
        setShowUploadFileDialog(true);

        if (selectedPath) {
            callApis();
        }
    };

    const handleCancelUploadFile = (event: any) => {
        setShowUploadFileDialog(false);
    };

    const handleCloseUploadFile = async () => {
        setShowUploadFileDialog(false);
        await callApis();
    };

    const handleDownloadFile = async (file: string) => {
        console.log('download file: ', file);
        const creds = getDatalakeRoleCredentials(config, identity as Identity);
        try {
            const bucket = `nnedl-core-${config.env}-${dataset.region}-curated`;
            const expireDuration = 3600;
            const url = await downloadFile(bucket, file, dataset.region!, creds, expireDuration);
            //console.log("download url: ", url);
            var element = document.createElement('a');
            var filename = url.substring(url.lastIndexOf('/') + 1);
            element.setAttribute('href', url);
            element.setAttribute('download', '');
            element.setAttribute('target', '_blank');
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } catch (error) {
            console.log(error);
            addError(new Error(error?.toString()));
        }
    };

    const handleDeleteFile = async (file: string) => {
        // console.log("calling the api...");
        setLoading(true);
        try {
            // console.log("deleting the file: ", file);
            // setBackdrop(true);
            let prefixString = removeFirstSubstring(selectedPath, dataset.name);
            console.log(dataset.name);
            // console.log("gotten prefix: ", prefixString);

            // console.log("delete prefix: ", prefixString);
            console.log(file.toString());

            const response = await commandApi.deleteFile(dataset.name, file);
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
                setActionMessage('Delete of the file failed!.');
                setOpenActionDone(true);
                setActionSeverity('error');
            } else {
                setActionMessage('Delete of the file succeeded!.');
                setOpenActionDone(true);
                setActionSeverity('info');
                setLoading(false);

                if (selectedPath) {
                    setLoading(true);
                    try {
                        let d;
                        const prefix = selectedPath.split('/').slice(1).join('/');
                        const prefixTerm = searchTerm
                            ? prefix
                                ? '/' + searchTerm
                                : '' + searchTerm
                            : '';
                        if (searchTerm) {
                            d = await queryApi.searchDataFiles(
                                dataset['name'],
                                maxKeys,
                                encodeURI(prefix + prefixTerm),
                            );
                        } else {
                            d = await queryApi.getDataFiles(
                                dataset['name'],
                                maxKeys,
                                encodeURI(prefix + prefixTerm),
                            );
                        }

                        setFiles(d['Contents']);
                        setLoading(false);
                    } catch (err) {
                        //logout();
                        console.log(err);
                        setLoading(false);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
        return;
    };

    const handleCreateFolderButtonClick = (event: any) => {
        setShowCreateFolderDialog(true);
    };

    const handleCreateSubsetButtonClick = (event: any) => {
        setShowCreateSubsetDialog(true);
    };

    const handleCreateSubset = async (subset: Subset) => {
        console.log('create subset: ', subset);
        setShowCreateSubsetDialog(false);
        try {
            setBackdrop(true);
            await commandApi.createSubset(
                subset.ds_name,
                subset.subset_name,
                subset.path,
                subset.schedule,
            );
        } catch (e) {
            console.log(e);
            addError(new Error(e?.toString()));
        } finally {
            setBackdrop(false);
        }
        onChangeTab(0);
    };

    const handleCancelCreateFolder = (event: any) => {
        setShowCreateFolderDialog(false);
    };

    const handleCancelCreateSubset = (event: any) => {
        setShowCreateSubsetDialog(false);
    };

    const handleCreateFolder = async (folderName: string) => {
        setShowCreateFolderDialog(false);
        try {
            // console.log("create new folder: ", folderName);
            setBackdrop(true);
            let prefixString = removeFirstSubstring(selectedPath, dataset.name);
            if (prefixString) {
                prefixString = prefixString.startsWith('/') ? prefixString.slice(1) : prefixString;
                prefixString = prefixString + '/';
            }
            // console.log("create folder prefix: ", prefixString);
            const response = await commandApi.createDsFolder(
                dataset.name,
                `${prefixString}${folderName}`,
            );
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            } else {
                onSelect(`${selectedPath}/${folderName}`);
                onDeleteFolder(null);
            }
        } catch (error) {
            console.log(error);
            addError(new Error(error?.toString()));
        } finally {
            setBackdrop(false);
        }
    };

    const handleDeleteFolder = async () => {
        try {
            setBackdrop(true);

            console.log('calling the api...');
            const response = await commandApi.deleteFolder(dataset.name, selectedPath);
            console.log(response);

            if (response && response.statusCode == 400) {
                addError(new Error(response.body));
                console.log('Delete failed!');

                setActionMessage('Delete failed! Folder not empty.');
                setOpenActionDone(true);
                setActionSeverity('error');
            } else {
                console.log('Delete succeeded!');
                setActionMessage('Folder deleted successfully!');
                setOpenActionDone(true);
                setActionSeverity('info');
                let struct = selectedPath.split('/');
                // @ts-ignore
                let toSelect = Array.apply(null, { length: struct.length - 1 })
                    .map(function (el, index) {
                        return struct[index];
                    })
                    .join('/');
                console.log('to select ', toSelect);
                setLoading(false);
                onDeleteFolder(`${selectedPath}`);
                onSelect(`${toSelect}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setBackdrop(false);
        }
    };

    const renderSubsetButton = () => {
        return (
            <Tooltip title="Make this folder a subset">
                <IconButton
                    color="default"
                    aria-label="Create subset"
                    onClick={handleCreateSubsetButtonClick}
                >
                    <FolderSpecialIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const handleCloseActionDone = () => {
        console.log('closing the action message alert...');
        setOpenActionDone(false);
        return;
    };

    const canRemoveOrUpdate = useCallback(() => {
        const username = identity?.username;

        if (dataset.managed) {
            return true;
        }

        const isDevOrReader =
            dataset.members.filter(
                (member) =>
                    member.username === username &&
                    (member.profile === 'reader' || member.profile === 'developer'),
            ).length > 0;

        return !isDevOrReader;
    }, [identity, dataset]);

    return (
        <div>
            <UploadFileDialog
                openState={showUploadFileDialog}
                onCancel={handleCancelUploadFile}
                onClose={handleCloseUploadFile}
                dataset={dataset}
                prefix={selectedPath}
            />
            <CreateFolderDialog
                openState={showCreateFolderDialog}
                onCancel={handleCancelCreateFolder}
                onSave={handleCreateFolder}
                prefix={selectedPath}
            />
            <CreateSubsetDialog
                openState={showCreateSubsetDialog}
                onCancel={handleCancelCreateSubset}
                onSave={(subset) => handleCreateSubset(subset)}
                prefix={selectedPath}
                dataset={dataset}
                enablePath={false}
            />
            <div className={classes.snackbar}>
                <Snackbar
                    className={classes.snackbar}
                    open={actionDone}
                    autoHideDuration={6000}
                    onClose={handleCloseActionDone}
                >
                    <Alert onClose={handleCloseActionDone} severity={actionSeverity}>
                        `{actionMessage}`
                    </Alert>
                </Snackbar>
            </div>
            <Breadcrumbs
                className={classes.breadcrumb}
                handleClick={onBreadcrumbClick}
                path={selectedPath}
            />
            {canRemoveOrUpdate() && (
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            id="searchInput"
                            size="small"
                            label="Search"
                            helperText="Files that begins with"
                            variant="outlined"
                            onChange={(e) => handleOnSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.buttonContainer}>
                        <Tooltip title="Delete folder">
                            <IconButton
                                color="default"
                                aria-label="Delete folder"
                                onClick={(el) => handleDeleteFolder()}
                            >
                                <DeleteIcon></DeleteIcon>
                            </IconButton>
                        </Tooltip>
                        {renderSubsetButton()}
                        <Tooltip title="Create folder">
                            <IconButton
                                color="default"
                                aria-label="Create folder"
                                onClick={handleCreateFolderButtonClick}
                            >
                                <CreateNewFolderIcon></CreateNewFolderIcon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Upload a file">
                            <IconButton
                                color="default"
                                aria-label="Upload"
                                onClick={handleUploadFile}
                            >
                                <PublishIcon></PublishIcon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
            {loading ? (
                <CircularProgressWrapper />
            ) : (
                <FileTable
                    files={files}
                    prefixPath={selectedPath}
                    onDownloadFile={handleDownloadFile}
                    onDeleteFile={handleDeleteFile}
                />
            )}
        </div>
    );
}
