import React, { useEffect, useState } from 'react';
import Title from '../Common/Title';
import SearchUsers from './SearchUsers';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { useAuth } from '../App/AuthContext';
import { useCommandApi, useQueryApi } from '../Apis';
import { useGlobalContext } from '../App/GlobalContext';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

function UserTable() {
    const { graphToken, authSession, logout } = useAuth();

    const { config } = useGlobalContext();

    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);

    const [users, setUsers] = useState<any>([]);

    const [columns] = useState([
        {
            headerName: 'Username',
            field: 'username',
            flex: 1,
            renderCell: (props: any) => {
                const rowData = props.row;
                if (rowData['type'] === 'machineuser') {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ComputerIcon />
                            <Box ml={2}>{rowData['username']}</Box>
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <PersonIcon />
                            <Box ml={2}>{rowData['username']}</Box>
                        </div>
                    );
                }
            },
        },
    ]);

    async function initializeData() {
        try {
            setUsers(await queryApi.getAllUsers());
        } catch (e) {
            logout();
        }
    }

    useEffect(() => {
        initializeData();
    }, []);

    async function handleAddUser(username: string) {
        try {
            await commandApi.createUser(username);
            initializeData();
        } catch (e) {
            logout();
        }
    }

    const CustomToolbar = ({ title }: { title: string }) => {
        return (
            <GridToolbarContainer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        m: 2,
                        mb: 0.5,
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    };

    return (
        <React.Fragment>
            <Box m={2}>
                <Title>Agile users</Title>
            </Box>
            <Box m={2}>
                <SearchUsers onDialogOk={handleAddUser} graphToken={graphToken} />
            </Box>
            <Box m={2}>
                <DataGrid
                    rows={users}
                    columns={columns}
                    getRowId={(row) => row.username}
                    slots={{
                        toolbar: () => <CustomToolbar title="NNEDL Users" />,
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10, page: 0 } },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick
                />
            </Box>
        </React.Fragment>
    );
}

export default UserTable;
