import React, { useEffect } from 'react';
import MemberTransferList from './MemberTransferList';
import { Profile } from '../../interfaces/profile';

type User = { username: string };
type Member = { username: string };

type Props = {
    members: Member[];
    users: User[];
    onAddMember: (user: User, profile: Profile) => void;
    onRemoveMember: (member: Member) => void;
    onChangeProfile: (profile: Profile) => void;
    profiles: Profile[];
};

export default function DatasetMembers({
    members,
    users,
    onAddMember,
    onRemoveMember,
    onChangeProfile,
    profiles,
}: Props) {
    const [otherUsers, setOtherUsers] = React.useState<User[]>([]);

    useEffect(() => {
        const filtered = users.filter((user: User) => {
            const dsMemberNames = members.map((x: Member) => x.username);
            return dsMemberNames.indexOf(user.username) === -1;
        });

        setOtherUsers(filtered);
    }, [users, members]);

    return (
        <React.Fragment>
            <MemberTransferList
                columns={[
                    { headerName: 'User', field: 'username', defaultSort: 'asc', flex: 0.6 },
                    // { headerName: 'Email', field: 'username', hide: true }, // TODO skal mappes op.
                    { headerName: 'Profile', field: 'profile', flex: 0.25 },
                ]}
                leftHeader="All users"
                leftItems={otherUsers}
                rightHeader="Members"
                rightItems={members}
                onLeftChanged={({ item: user, profile }) => {
                    onAddMember(user, profile);
                }}
                onRightChanged={({ item: member }) => {
                    onRemoveMember(member);
                }}
                editable={true}
                onChangeProfile={onChangeProfile}
                profiles={profiles}
            />
        </React.Fragment>
    );
}
