import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

interface SimpleBreadcrumbsProps {
    className?: string;
    handleClick: (path: string) => void;
    path: string;
}

export default function SimpleBreadcrumbs({
    className,
    handleClick,
    path,
}: SimpleBreadcrumbsProps) {
    if (!path) {
        return <></>;
    }

    const pathElements = path.split('/');
    return (
        <Breadcrumbs className={className} aria-label="breadcrumb">
            {pathElements.map((value, index) => {
                if (index < pathElements.length - 1) {
                    const path = pathElements.slice(0, index + 1).join('/');
                    return (
                        <Link
                            color="inherit"
                            key={index}
                            href="#"
                            onClick={() => handleClick(path)}
                        >
                            {value}
                        </Link>
                    );
                } else {
                    return (
                        <Typography key={index} color="textPrimary">
                            {value}
                        </Typography>
                    );
                }
            })}
        </Breadcrumbs>
    );
}
